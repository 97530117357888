import { Button } from '@/components/Button';
import { Icon } from '@/components/Icon';
import { useStableProps } from '@/hooks/useStable';
import useUserAuth from '@/hooks/useUserAuth';
import NotAMemberModalImage from '@/mabra/public/image/not-a-member-modal.png';
import { StandaloneComponent } from '@/types/component';
import { StandaloneSaveArticle, StandaloneSaveArticleProps } from 'base/components/SaveArticle';
import NextImage from 'next/image';

export const MabraStandaloneSaveArticle: StandaloneComponent<StandaloneSaveArticleProps> = (props) => {
  const { logInUrl, signUpUrl } = useUserAuth();

  const stableProps = useStableProps(
    {
      loose: {
        options: {
          colors: 'primary',
        },
      },
      modal: (
        <div className="relative flex flex-col-reverse items-center md:flex-row">
          <Icon options={{ size: 'medium', className: 'cursor-pointer absolute right-2 top-2' }} name="close" />
          <NextImage src={NotAMemberModalImage} alt="" />
          <div className="flex max-w-[424px] flex-col items-center gap-y-2.25 px-10 py-6 md:max-w-90 md:gap-y-3 md:py-0">
            <span className="pb-2 text-center text-headline-xs md:pb-4 md:text-headline-sm">
              För att spara den här artiklen måste du vara inloggad
            </span>
            <Button
              href={logInUrl}
              content="Logga in på ditt konto"
              options={{ rel: 'nofollow', size: 'small', className: 'text-center' }}
            />
            <span className="text-headline-2xs md:text-headline-xs">eller</span>
            <Button
              href={signUpUrl}
              options={{ rel: 'nofollow', colors: 'secondary', size: 'small', className: 'text-center' }}
              content="Skapa ett konto kostnadsfritt"
            />
          </div>
        </div>
      ),
    },
    props,
  );

  return <StandaloneSaveArticle {...stableProps} />;
};
