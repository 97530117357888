import { tw } from '@/utils/tw';
import BaseAuthorsListTheme from 'base/components/AuthorsList/theme';

const ListItemLink = tw.theme({
  extend: BaseAuthorsListTheme.ListItemLink,
  base: ['w-full'],
});

const ListItemImage = tw.theme({
  extend: BaseAuthorsListTheme.ListItemImage,
  slots: {
    base: ['rounded-full'],
  },
});

const AuthorsListTheme = tw.theme({
  extend: BaseAuthorsListTheme,
  slots: {
    base: [],
    headline: ['text-headline'],
    description: ['hidden'],
    list: ['mt-3', 'w-full', 'gap-6', 'grid', 'grid-cols-1', 'gap-6', 'mb-6', 'sm:grid-cols-2', 'md:grid-cols-3'],
    listItem: [
      'w-full',
      'min-w-88',
      'gap-x-3',
      'p-3',
      'flex',
      'flex-row',
      'items-center',
      'transition-colors',
      'group',
      'rounded',
    ],
    listItemTitle: ['text-headline-xs'],
  },
  variants: {
    colors: {
      default: { listItem: ['bg-gray-50'] },
    },
  },
});

export default Object.assign(AuthorsListTheme, {
  ListItemLink,
  ListItemImage,
  ListItemIcon: BaseAuthorsListTheme.ListItemIcon,
});
