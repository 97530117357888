import { Icon } from '@/components/Icon';
import BannerImage from '@/mabra/public/image/course/mabra-banner-image.png';
import { getUrl } from '@/utils/getUrl';
import { mergeProps } from '@/utils/merge';
import { withTrackingAttributes } from '@/utils/withTrackingAttributes';
import { StandaloneCourseCountdown } from 'base/components/CourseCountdown';
import Tracking from 'base/components/TrackingComponent';
import NextImage from 'next/image';

const ctsUrl = getUrl('/21-dagar');

const ctsAttrs = withTrackingAttributes({
  category: 'promotion_banner',
  name: 'countdown',
  url: ctsUrl ? ctsUrl.toString() : '/21-dagar',
  extraInfo: [{ campaign: 'mabra_course_21_dagar' }],
});

export const MabraStandaloneCourseCountdown: typeof StandaloneCourseCountdown = (props) => {
  return (
    <Tracking options={{ wrapChildren: true, ctsAttrs }}>
      <StandaloneCourseCountdown
        {...mergeProps(
          {
            logo: <NextImage src={BannerImage} alt={'Banner Image'} />,
            description:
              'Grattis till ett bra beslut! Under 21 dagar kommer vi peppa och coacha dig till nya hälsosamma vanor.',
            button: {
              content: (
                <>
                  Gå till utmaningen
                  <Icon name="chevronRight" />
                </>
              ),
              href: '/21-dagar-start',
            },
          },
          props,
        )}
      />
    </Tracking>
  );
};
