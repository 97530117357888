import { tw } from '@/utils/tw';
import DialogTheme from 'base/components/Dialog/theme';

const { CloseIcon, DialogIcon, Button } = DialogTheme;

const CloseButton = tw.theme({
  extend: DialogTheme.CloseButton,
  base: ['absolute', 'right-3', 'top-3', 'max-h-6', 'w-6', 'px-0'],
});

const CloseIconTheme = tw.theme({
  extend: CloseIcon,
  slots: {
    base: ['fill-black'],
  },
});

const Dialog = tw.theme({
  extend: DialogTheme,
  slots: {
    base: ['bg-gray-50'],
  },
});

export default Object.assign(Dialog, { CloseIcon: CloseIconTheme, DialogIcon, Button, CloseButton });
