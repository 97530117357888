import { CourseCountdown } from '@/components/CourseCountdown';
import useUserAuth from '@/hooks/useUserAuth';
import { LabradorComponent } from '@/types/component';

export const MabraLabradorCourseCountdown: LabradorComponent = ({ type, meta }) => {
  const { userTags } = useUserAuth();
  const isUserSubscribed = userTags.includes('mabra_fitness_course');

  if (!isUserSubscribed) {
    return null;
  }

  return <CourseCountdown />;
};
